.App {
  text-align: center;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}


.MailchimpStyle button {
  clear: left;
  border-style: solid;
  color: black;
  background-color: white;
  border-radius: 4px;
  font-family: 'Courier New';
  margin: 2px;
}

.MailchimpStyle text {
  clear: left;
  color: yellow;
  background-color: white;
  margin: 2px;
}


.label-logo {
  height: 80vh;
  pointer-events: none;
}

a {
    text-align: center;
    /* font-weight: 600; */
    color: #FF8484;
    text-decoration: underline;

}



.App-header {
  background-color: #FFD99B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: Black;
}


.App-link {
  color: #61dafb;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}


.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
