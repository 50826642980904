
.sidebar {
    background-color: white;
    width: 10vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    /* min-height: 100vh !important; */
    z-index: 100;
    padding: 20% 0 0;
    text-decoration: none !important;
}
/* and (max-width: 600px)  */
@media screen and (max-width: 600px) { 
    .sidebar {
        background-color: white;
        width: 100vw;
        height: 9vh !important;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        padding: 0% 0 0;
        text-decoration: none !important;
    }    
}

#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    text-decoration: none;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}
